import React, { useEffect, useLayoutEffect } from "react";
import {
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Center,
  Spinner,
  Select,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { Hooks } from "services";
import InputMask from "components/atoms/InputMask";
import { useRedux } from "hooks/redux-hooks";
import { getDayName } from "utils/converterDay";

interface AddControlTimeProps {
  onClose: () => void;
  projectId: number;
  controlAreaId: number;
  onSuccess?: () => void;
}

interface Form {
  weekOfDay: string;
  endHour: string;
  startHour: string;
}

function AddControlTime(props: AddControlTimeProps) {
  const [{ auth }] = useRedux();
  const [getPeriods, statPeriods, dataPeriods] = Hooks.ListPeriods();
  const [addShift, statShift] = Hooks.AddShiftWithPeriodId();
  const toast = useToast();

  useLayoutEffect(() => {
    getPeriods(props.controlAreaId);
  }, []);

  useEffect(() => {
    if (statShift === "fulfilled") {
      if (props.onSuccess) {
        toast({title: "Kontrol Saati Başarıyla Eklendi!"})
        props.onSuccess();
      }
    }
  }, [statShift]);

  if (statPeriods !== "fulfilled") {
    return (
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Kontrol Saati Oluştur</DrawerHeader>
        <Center>
          <Spinner />
        </Center>
      </DrawerContent>
    );
  }

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Kontrol Saati Oluştur</DrawerHeader>

      <Formik<Form>
        initialValues={{
          weekOfDay: "-1",
          endHour: "",
          startHour: "",
        }}
        onSubmit={(values) => {
          addShift(props.controlAreaId, Number(values.weekOfDay), {
            startHourString: values.startHour,
            endHourString: values.endHour,
          });
        }}
      >
        <Form>
          <DrawerBody>
            <FormControl mt="3">
              <FormLabel>Gün</FormLabel>
              <Field as={Select} name="weekOfDay">
                <option value="-1" disabled>
                  Gün Seçiniz!
                </option>
                {dataPeriods?.data.data.map((period: any) => (
                  <option value={period.id} key={period.id}>
                    {getDayName(period.dayOfWeek)}
                  </option>
                ))}
              </Field>
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Başlangıç Saati</FormLabel>
              <Field as={InputMask} name="startHour" mask="99:99" />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Bitiş Saati</FormLabel>
              <Field as={InputMask} name="endHour" mask="99:99" />
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              Vazgeç
            </Button>
            <Button colorScheme="blue" type="submit" isLoading={statShift === "pending"}>
              Oluştur
            </Button>
          </DrawerFooter>
        </Form>
      </Formik>
    </DrawerContent>
  );
}

export default AddControlTime;
