import React, { useEffect, useLayoutEffect } from "react";
import {
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Center,
  Spinner,
  Select,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";
import { Hooks } from "services";
import InputMask from "components/atoms/InputMask";
import { useRedux } from "hooks/redux-hooks";
import { getDayIndex, getDayName } from "utils/converterDay";
import { DAYS } from "./AddQuestion";

interface AddControlTimeProps {
  onClose: () => void;
  projectId: number;
  controlAreaId: number;
  onSuccess?: () => void;
}

interface Form {
  weekOfDay: string;
}

function AddControlTime(props: AddControlTimeProps) {
  const [{ auth }] = useRedux();
  const [getPeriods, statPeriods, dataPeriods] = Hooks.ListPeriods();
  const [addPeriod, statAddPeriod] = Hooks.AddPeriod();
  const toast = useToast();

  useLayoutEffect(() => {
    getPeriods(props.controlAreaId);
  }, []);

  useEffect(() => {
    if (statAddPeriod === "fulfilled") {
      if (props.onSuccess) {
        toast({ title: "Kontrol Günü Başarıyla Eklendi!" });
        props.onSuccess();
      }
    }
  }, [statAddPeriod]);

  if (statPeriods !== "fulfilled") {
    return (
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Kontrol Günü Ekle</DrawerHeader>
        <Center>
          <Spinner />
        </Center>
      </DrawerContent>
    );
  }

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Kontrol Saati Oluştur</DrawerHeader>

      <Formik<Form>
        initialValues={{
          weekOfDay: "-1",
        }}
        onSubmit={(values) => {
          addPeriod(props.controlAreaId, {
            dayOfWeek: Number(values.weekOfDay),
          });
        }}
      >
        <Form>
          <DrawerBody>
            <FormControl mt="3">
              <FormLabel>Gün</FormLabel>
              <Field as={Select} name="weekOfDay">
                <option value="-1" disabled>
                  Gün Seçiniz!
                </option>
                {DAYS.filter((dayOfWeek) => {
                  let index = dataPeriods?.data.data.find(
                    (item: any) => getDayName(item.dayOfWeek) === dayOfWeek
                  );
                  return !index;
                }).map((dayOfWeek, index) => {
                  return (
                    <option value={getDayIndex(dayOfWeek)} key={getDayIndex(dayOfWeek)}>
                      {dayOfWeek}
                    </option>
                  );
                })}
              </Field>
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              Vazgeç
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isLoading={statAddPeriod === "pending"}
            >
              Oluştur
            </Button>
          </DrawerFooter>
        </Form>
      </Formik>
    </DrawerContent>
  );
}

export default AddControlTime;
