import { extendTheme } from "@chakra-ui/react";

export const colors = {
  background: "#f3f4f6",
  purple: {
    50: "#eceef7",
    100: "#c7cde6",
    200: "#a1abd6",
    300: "#7b89c6",
    400: "#4357AD",
    500: "#4357AD",
    600: "#3d50bb",
    700: "#36468a",
    800: "#222c57",
    900: "#141a34",
  },

  red: {
    50: "#fbe9ed",
    100: "#f4bcc8",
    200: "#ed8fa4",
    300: "#e6627f",
    400: "#db1f48",
    500: "#db1f48",
    600: "#af193a",
    700: "#6e1024",
    800: "#580c1d",
    900: "#2c060e",
  },
  green: {
    50: "#effbf4",
    100: "#cff2df",
    200: "#b0e9ca",
    300: "#80dca9",
    400: "#60d394",
    500: "#60d394",
    600: "#4da976",
    700: "#306a4a",
    800: "#26543b",
    900: "#132a1e",
  },
  orange: {
    50: "#fbf6ee",
    100: "#f4e3cd",
    200: "#edd0ac",
    300: "#e6bd8b",
    400: "#dba159",
    500: "#dba159",
    600: "#af8147",
    700: "#836135",
    800: "#42301b",
    900: "#161009",
  },
};

export const theme = extendTheme({
  colors: colors,
  components: {
    Input: {
      baseStyle: {
        borderColor: "gra.600"
      }
    }
  }
});
