import React, { useEffect, useState } from "react";
import {
  SimpleGrid,
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Heading,
  Text,
  Divider,
  Flex,
  Checkbox,
  Button,
  Center,
  useToast,
} from "@chakra-ui/react";

import { useHistory } from "react-router-dom";

import MainTemplate from "components/templates/Main";
import SectionTitle from "components/molecules/SectionTitle";
import InputMask from "components/atoms/InputMask";

import Card from "components/atoms/Card";
import { IoAdd, IoChevronForward } from "react-icons/io5";
import { Hooks } from "services";
import { useRedux } from "hooks/redux-hooks";

const DAYS = [
  "Pazar",
  "Pazartesi",
  "Salı",
  "Çarşamba",
  "Perşembe",
  "Cuma",
  "Cumartesi",
];

const AddControlArea = () => {
  const [{ auth }] = useRedux();
  const { push } = useHistory();
  const [formFields, setForm] = useState({
    name: "",
    code: "",
    period: "1",
    periods: [] as any[],
  });
  const [activeIndex, setActiveIndex] = useState<number | undefined>();
  const [createCA, statusCA, dataCA] = Hooks.AddControlAreaMultiple();
  const toast = useToast();

  function handleSubmit() {
    var hasNull = false;
    formFields.periods.forEach((period) => {
      if (formFields.period === "1" && period.shifts.length === 0) {
        hasNull = true;
        toast({
          status: "warning",
          position: "top-right",
          title: `${
            DAYS[period.dayOfWeek]
          } için vardiya tanımlaması yapılmadı!`,
        });
      }
    });
    if (!hasNull) {
      createCA({
        barcode: formFields.code,
        name: formFields.name,
        periodType: Number(formFields.period),
        projectID: auth.user.project.id,
        periods: formFields.periods,
      });
    }
  }

  useEffect(() => {
    const _data = dataCA?.data as unknown as any;
    if (statusCA === "fulfilled") {
      toast({
        title: "Başarılı!",
        description: "Kontrol bölgesi başarıyla oluşturuldu.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      push("/control-area");
    } else if (statusCA === "rejected") {
      toast({
        title: "Hata Oluştu!",
        description: _data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [statusCA]);

  return (
    <MainTemplate>
      <SectionTitle title="Kontrol Alanı Ekle" />
      <Card>
        <SimpleGrid columns={[1, 2]} spacing="10">
          <Box>
            <FormControl mt="3">
              <FormLabel>Kontrol Bölgesi Adı</FormLabel>
              <Input
                name="name"
                value={formFields.name}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Kod</FormLabel>
              <Input
                name="code"
                value={formFields.code}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl mt="3" mb="5">
              <FormLabel>Periyot</FormLabel>
              <Select
                name="period"
                value={formFields.period}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                    periods: [],
                  }))
                }
              >
                <option value="1">Günlük</option>
                <option value="2">Haftalık</option>
                <option value="3">Aylık</option>
              </Select>
            </FormControl>
            {formFields.period === "3" ? (
              <FormControl mt="3">
                <FormLabel>Ayın Günü</FormLabel>
                <Input
                  value={formFields.periods[0]?.dayOfMonth?.toString() || ""}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      periods: [
                        {
                          dayOfMonth: Number(e.target.value),
                          shifts: [{ startHour: "00:00", endHour: "23:59" }],
                        },
                      ],
                    }))
                  }
                />
              </FormControl>
            ) : (
              <Box mt="2">
                <Heading size="sm">Günler</Heading>
                <Divider my="1" />
                {DAYS.map((dayOfWeek, index) => {
                  const isChecked = !!formFields.periods.find(
                    (item) => item.dayOfWeek === index
                  );
                  return (
                    <Flex
                      backgroundColor={"gray.50"}
                      mb="1"
                      p="2"
                      key={index}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Flex alignItems="center">
                        <Checkbox
                          isChecked={isChecked}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setForm((prev) => ({
                                ...prev,
                                periods:
                                  formFields.period === "2"
                                    ? [
                                        {
                                          dayOfWeek: index,
                                          shifts: [
                                            {
                                              startHour: "00:00",
                                              endHour: "23:59",
                                            },
                                          ],
                                        },
                                      ]
                                    : [
                                        ...prev.periods,
                                        { dayOfWeek: index, shifts: [] },
                                      ],
                              }));
                            } else {
                              if (index === activeIndex) {
                                setActiveIndex(undefined);
                              }
                              setForm((prev) => ({
                                ...prev,
                                periods: [...prev.periods].filter(
                                  (item) => item.dayOfWeek !== index
                                ),
                              }));
                            }
                          }}
                        />
                        <Text
                          fontWeight="bold"
                          ml="2"
                          color="gray.600"
                          fontSize="sm"
                        >
                          {dayOfWeek}
                        </Text>
                      </Flex>
                      <Button
                        size="xs"
                        colorScheme="gray"
                        disabled={!isChecked}
                        rightIcon={<IoChevronForward />}
                        onClick={() => setActiveIndex(index)}
                      >
                        Kontrol Saatleri
                      </Button>
                    </Flex>
                  );
                })}
              </Box>
            )}
          </Box>
          <Box>
            {activeIndex !== undefined && (
              <Box pt="3">
                <Heading size="md">{DAYS[activeIndex]}</Heading>
                <Divider my="2" />
                <Box>
                  {formFields.periods
                    .find((item) => item.dayOfWeek === activeIndex)
                    ?.shifts.map((shift: any, index: number) => (
                      <SimpleGrid mb="3" columns={2} spacing="2" key={index}>
                        <FormControl fontSize="sm">
                          <FormLabel fontSize="sm">Başlangıç Saati</FormLabel>
                          <InputMask
                            size="sm"
                            name="startHour"
                            value={shift.startHour}
                            onChange={(e: any) => {
                              const tmpPeriods = [...formFields.periods];
                              const _period = tmpPeriods.find(
                                (item) => item.dayOfWeek === activeIndex
                              );
                              _period.shifts[index][e.target.name] =
                                e.target.value;
                              const _index = tmpPeriods.findIndex(
                                (item) => item.dayOfWeek === _period.dayOfWeek
                              );
                              if (_index !== -1) {
                                tmpPeriods[_index] = _period;
                              }
                              setForm((prev) => ({
                                ...prev,
                                periods: tmpPeriods,
                              }));
                            }}
                            mask={"99:99"}
                          />
                        </FormControl>
                        <FormControl size="sm">
                          <FormLabel fontSize="sm">Bitiş Saati</FormLabel>
                          <InputMask
                            mask="99:99"
                            size="sm"
                            name="endHour"
                            value={shift.endHour}
                            onChange={(e: any) => {
                              const tmpPeriods = [...formFields.periods];
                              const _period = tmpPeriods.find(
                                (item) => item.dayOfWeek === activeIndex
                              );
                              _period.shifts[index][e.target.name] =
                                e.target.value;
                              const _index = tmpPeriods.findIndex(
                                (item) => item.dayOfWeek === _period.dayOfWeek
                              );
                              if (_index !== -1) {
                                tmpPeriods[_index] = _period;
                              }
                              setForm((prev) => ({
                                ...prev,
                                periods: tmpPeriods,
                              }));
                            }}
                          />
                        </FormControl>
                      </SimpleGrid>
                    ))}
                  <Center mt="5">
                    <Button
                      onClick={() => {
                        const tmpPeriods = [...formFields.periods];
                        const _period = tmpPeriods.find(
                          (item) => item.dayOfWeek === activeIndex
                        );
                        _period.shifts = [
                          ..._period.shifts,
                          {
                            startHour: "",
                            endHour: "",
                          },
                        ];
                        const _index = tmpPeriods.findIndex(
                          (item) => item.dayOfWeek === _period.dayOfWeek
                        );
                        if (_index !== -1) {
                          tmpPeriods[_index] = _period;
                        }
                        setForm((prev) => ({
                          ...prev,
                          periods: tmpPeriods,
                        }));
                      }}
                      size="sm"
                      variant="ghost"
                      leftIcon={<IoAdd />}
                    >
                      Vardiya Ekle
                    </Button>
                  </Center>
                </Box>
              </Box>
            )}
          </Box>
        </SimpleGrid>
        <Flex justifyContent="flex-end">
          <Button
            colorScheme="blue"
            isLoading={statusCA === "pending"}
            onClick={handleSubmit}
          >
            Oluştur
          </Button>
        </Flex>
      </Card>
    </MainTemplate>
  );
};

export default AddControlArea;
