import { RepeatIcon } from "@chakra-ui/icons";
import {
  Button,
  Drawer,
  DrawerOverlay,
  Flex,
  IconButton,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import Confirm from "components/atoms/Confirm";
import DataTable from "components/atoms/DataTable";
import StatusBadge from "components/atoms/StatusBadge";
import SectionTitle from "components/molecules/SectionTitle";
import Sure from "components/molecules/Sure";
import AddControlTime from "components/organizms/AddControlTime";
import AddControlDay from "components/organizms/AddControlDay";
import MainTemplate from "components/templates/Main";
import { useRedux } from "hooks/redux-hooks";
import { flatMap } from "lodash";
import React, { useLayoutEffect, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { mainPaths } from "router/path";
import { ControlPointListResponse, Hooks } from "services";
import { getDayName } from "utils/converterDay";
import Card from "../atoms/Card";

type Params = {
  projectId: string;
  controlAreaId: string;
};

function ControlTimes({ match, history }: RouteComponentProps<Params>) {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const periodDisc = useDisclosure();
  const [getProjectList, status, data] = Hooks.ProjectList();
  const [getControlAreaList, cAStat, cAData] = Hooks.ControlAreaList();
  const [getCA, statCA, dataCA] = Hooks.GetPeriodListByControlAreaId();
  const [deleteShift, statDeleteShift] = Hooks.DeleteShift();
  // const [getControlPointList, cPStat, cPData] = Hooks.ControlShiftList();
  // const [updateCP, statusCP, dataCP] = Hooks.UpdateShift();
  const [{ auth }] = useRedux();
  const [stt, setStt] = useState<any>();
  const [deletingShift, setDeletingShift] = useState<number>();

  async function willMount() {
    // await getProjectList(auth.user.id);
    getCA(Number(match.params.controlAreaId));
  }

  useLayoutEffect(() => {
    willMount();
  }, []);

  const controlPointList = dataCA?.data as unknown as ControlPointListResponse;

  useEffect(() => {
    if (statDeleteShift === "fulfilled") {
      setDeletingShift(undefined);
      willMount();
    }
  }, [statDeleteShift]);

  return (
    <MainTemplate>
      <SectionTitle
        title="Kontrol Saatleri"
        button={{
          colorScheme: "teal",
          onClick: periodDisc.onToggle,
          title: "Yeni Kontrol Günü Ekle",
        }}
        button2={{
          colorScheme: "blue",
          onClick: onToggle,
          title: "Yeni Kontrol Saati Ekle",
        }}
      />
      <Card>
        {statCA !== "fulfilled" ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        ) : (
          <DataTable
            columns={[
              // {
              //   name: "Id",
              //   selector: "id",
              //   sortable: true,
              // },
              // {
              //   name: "Periyod ID",
              //   selector: "id",
              // },
              // {
              //   name: "Kontrol Saat Id",
              //   selector: "shiftId",
              // },
              {
                name: "Periyod",
                sortable: true,
                cell: (row) => getDayName(row.dayOfWeek),
              },
              {
                name: "Başlangıç Saati",
                selector: "startHour",
                sortable: true,
              },
              {
                name: "Bitiş Saati",
                selector: "endHour",
                sortable: true,
              },
              // {
              //   name: "Durum",
              //   selector: "isActive",
              //   cell: (row: any) => {
              //     return <StatusBadge stat={row.isActive} />;
              //   },
              //   sortable: true,
              // },

              {
                name: "Aktif / Pasif",
                cell: (row: any, index: number) => {
                  if (auth.user.userRoleID === 3) return null;
                  return (
                    <Confirm
                      title="Onay Gerekli?"
                      text="Bu vardiya silinecek, işleme devam edilsin mi?"
                      colorScheme="red"
                      onConfirm={() => {
                        try {
                          deleteShift(
                            Number(match.params.controlAreaId),
                            row.id,
                            row.shiftId
                          );
                        } catch (error) {
                          console.log(error);
                        }
                        setDeletingShift(row.shiftId);
                      }}
                    >
                      {({ onOpen }) => (
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={(e) => {
                            onOpen();
                          }}
                          isLoading={deletingShift === row.shiftId}
                        >
                          Kaldır
                        </Button>
                      )}
                    </Confirm>
                  );
                },
              },
            ]}
            data={flatMap(
              dataCA?.data.data.map((item: any) =>
                item.shifts.map((shift: any) => ({
                  ...shift,
                  ...item,
                  shiftId: shift.id,
                }))
              )
            )}
          />
        )}
      </Card>
      {/* <Sure
        isOpen={deletingShift !== undefined}
        onClose={() => setDeletingShift(undefined)}
        onSuccess={() => {
          if(deletingShift !== undefined) {
            
          }
        }}
      /> */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay></DrawerOverlay>
        <AddControlTime
          controlAreaId={Number(match.params.controlAreaId)}
          projectId={auth.user.project.id}
          onClose={() => {}}
          onSuccess={() => {
            onClose();
            willMount();
          }}
        />
      </Drawer>
      <Drawer isOpen={periodDisc.isOpen} placement="right" onClose={periodDisc.onClose}>
        <DrawerOverlay></DrawerOverlay>
        <AddControlDay
          controlAreaId={Number(match.params.controlAreaId)}
          projectId={auth.user.project.id}
          onClose={() => {}}
          onSuccess={() => {
            periodDisc.onClose();
            willMount();
          }}
        />
      </Drawer>
    </MainTemplate>
  );
}

export default ControlTimes;
