import { Checkbox, SimpleGrid } from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";

type DaySelectProps = {
  isMultiple?: boolean;
  onChange?: (values: number[]) => void;
  defaultValue?: number[];
};

const DaySelect: FC<DaySelectProps> = (props) => {
  const [selectedDays, setSelectedDays] = useState<number[]>(props.defaultValue || []);

  function toggle(number: number) {
    selectedDays.includes(number)
      ? setSelectedDays((prev) => prev.filter((item) => item !== number))
      : setSelectedDays((prev) => props.isMultiple? [...prev, number] : [number]);
  }

  useEffect(() => {
    if (props.onChange) {
      props.onChange(selectedDays);
    }
  }, [selectedDays]);

  return (
    <SimpleGrid columns={2} mt="3">
      <Checkbox
        isChecked={selectedDays.includes(1)}
        onChange={() => toggle(1)}
      >
        Pazartesi
      </Checkbox>
      <Checkbox
        isChecked={selectedDays.includes(2)}
        onChange={() => toggle(2)}
      >
        Salı
      </Checkbox>
      <Checkbox
        isChecked={selectedDays.includes(3)}
        onChange={() => toggle(3)}
      >
        Çarşamba
      </Checkbox>
      <Checkbox
        isChecked={selectedDays.includes(4)}
        onChange={() => toggle(4)}
      >
        Perşembe
      </Checkbox>
      <Checkbox
        isChecked={selectedDays.includes(5)}
        onChange={() => toggle(5)}
      >
        Cuma
      </Checkbox>
      <Checkbox
        isChecked={selectedDays.includes(6)}
        onChange={() => toggle(6)}
      >
        Cumartesi
      </Checkbox>
      <Checkbox
        isChecked={selectedDays.includes(0)}
        onChange={() => toggle(0)}
      >
        Pazar
      </Checkbox>
    </SimpleGrid>
  );
};

export default DaySelect;
