import React, { FC, createContext, useContext, useState, useEffect } from "react";

type SaveFilterData = (_: { key: string; data: any }) => void;

type FilterContextTypes = {
  data: any;
  saveFilterData: SaveFilterData;
};
const FilterContext = createContext<FilterContextTypes>(
  {} as FilterContextTypes
);

export const FilterDataProvider: FC = ({ children }) => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    const _dataStr = localStorage.getItem("filter-data");
    if (_dataStr) {
      console.log(JSON.parse(_dataStr));
      setData(JSON.parse(_dataStr));
    }
  }, []);

  function saveFilterData(_: any) {
    setData((prev: any) => ({ ...prev, [_.key]: [_.data] }));
    localStorage.setItem(
      "filter-data",
      JSON.stringify({ ...data, [_.key]: [_.data] })
    );
  }

  return (
    <FilterContext.Provider value={{ data, saveFilterData }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterData = () => {
  return useContext(FilterContext);
};
