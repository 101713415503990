import React from "react";
import { Box, Button, Flex, Text, Icon } from "@chakra-ui/react";
import { useCurrentUser } from "hooks/redux-hooks";
import { FaPlus } from "react-icons/fa";

function SectionTitle(props: {
  title: string;
  button?: { title: string; onClick: () => void; colorScheme: string };
  button2?: { title: string; onClick: () => void; colorScheme: string };
  color?: string;
}) {
  const user = useCurrentUser();
  return (
    <Flex alignItems="center" justifyContent="space-between" mb="5" mt="3">
      <Flex alignItems="center">
        <Box
          w="25px"
          h="25px"
          bgColor={!!props.color ? `${props.color}.500` : "green.500"}
          borderRadius="md"
          mr="2"
        />
        <Text
          fontSize="md"
          color="black"
          fontWeight="bold"
          textTransform="uppercase"
          mt="-2px"
        >
          {props.title}
        </Text>
      </Flex>
      <Flex alignItems="center">
        {user.userRoleID !== 3 && props.button && (
          <Button
            size="sm"
            colorScheme={props.button.colorScheme}
            onClick={props.button.onClick}
            leftIcon={<Icon as={FaPlus} />}
          >
            <Text fontSize="small">{props.button.title}</Text>
          </Button>
        )}
        {user.userRoleID !== 3 && props.button2 && (
          <Button
            size="sm"
            ml="2"
            colorScheme={props.button2.colorScheme}
            onClick={props.button2.onClick}
            leftIcon={<Icon as={FaPlus} />}
          >
            <Text fontSize="small">{props.button2.title}</Text>
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

export default SectionTitle;
