type p = number | string;

export const mainPaths = {
  Home: ["/", "/home"],
  ControlArea: (projectId: p = ":projectId") => `/control-area`,
  ControlPoint: (
    projectId: p = ":projectId",
    controlAreaId: p = ":controlAreaId"
  ) => `${mainPaths.ControlArea(projectId)}/${controlAreaId}/control-point`,
  ControlTime: (
    projectId: p = ":projectId",
    controlAreaId: p = ":controlAreaId"
  ) => `${mainPaths.ControlArea(projectId)}/${controlAreaId}/control-time`,
  Question: (
    projectId: p = ":projectId",
    controlAreaId: p = ":controlAreaId",
    controlPointId: p = ":controlPointId"
  ) =>
    `${mainPaths.ControlPoint(
      projectId,
      controlAreaId
    )}/${controlPointId}/question`,
  User: "/user",
  Mail: "/mail",
  QuestionType: "/question-type",
  Notice: "/notice",
  Settings: "/settings",
  Report: "/report",
  List: "/list",
  InfoEdit: "/info-edit",
};

export const authPaths = {
  Login: ["/", "/login", "/signin"],
};
