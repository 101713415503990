import React, { Fragment, useEffect, useLayoutEffect } from "react";
import {
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import {
  Hooks,
  UserTypeListResponse,
  ProjectShiftListResponse,
} from "services";
import { useRedux } from "hooks/redux-hooks";

interface AddQuestionProps {
  onClose: () => void;
  controlAreaId: number;
  controlPointId: number;
  projectId: number;
  onSuccess?: () => void;
}

interface Form {
  questionText: string;
  userTypeID: string;
  questionTypeID: string;
  initialValue: string;
  endValue: string;
  projectShiftID: string;
}

export const DAYS = [
  "Pazartesi",
  "Salı",
  "Çarşamba",
  "Perşembe",
  "Cuma",
  "Cumartesi",
  "Pazar",
];

function AddQuestion(props: AddQuestionProps) {
  const [getTypes, statusTypes, dataTypes] = Hooks.QuestionTypeList();
  const [getUserTypes, statusUserTypes, dataUserTypes] = Hooks.UserTypeList();
  const [postAddQuestion, statusAddQuest, dataAddQuest] = Hooks.AddQuestion();
  const [{ auth }] = useRedux();
  const toast = useToast();

  function onValid(values: Form) {
    postAddQuestion({
      endValue: values.endValue.toString(),
      initialValue: values.initialValue.toString(),
      questionText: values.questionText,
      questionTypeID: parseInt(values.questionTypeID),
      userTypeID: parseInt(values.userTypeID),
      controlAreaID: props.controlAreaId,
      projectID: auth.user.project.id,
      controlPointID: props.controlPointId,
    });
  }

  useLayoutEffect(() => {
    getUserTypes();
    getTypes();
  }, []);

  useEffect(() => {
    const _data = (dataAddQuest?.data as unknown) as any;
    if (statusAddQuest === "fulfilled" && _data.success) {
      toast({
        title: "Başarılı!",
        description: "Soru başarıyla oluşturuldu.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      props.onSuccess && props.onSuccess();
      props.onClose();
    } else if (statusAddQuest === "fulfilled" && !_data.success) {
      toast({
        title: "Hata Oluştu!",
        description: _data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [statusAddQuest]);

  const typeList = (dataTypes?.data as unknown) as UserTypeListResponse;
  const typeUserList = (dataUserTypes?.data as unknown) as UserTypeListResponse;

  return (
    <DrawerContent w="500px">
      <DrawerCloseButton />
      <DrawerHeader>Soru Ekle</DrawerHeader>

      <Formik<Form>
        initialValues={{
          questionText: "",
          endValue: "",
          initialValue: "",
          questionTypeID: "",
          userTypeID: "",
          projectShiftID: "",
        }}
        onSubmit={onValid}
      >
        {({ values }) => (
          <Form>
            <DrawerBody maxH="calc(100vh - 250px)" overflowY="scroll">
              <FormControl>
                <FormLabel>Soru</FormLabel>
                <Field
                  as={Textarea}
                  name="questionText"
                  required
                  minLength="1"
                />
              </FormControl>
              <FormControl mt="3">
                <FormLabel>Soru Tipi</FormLabel>
                <Field
                  as={Select}
                  name="userTypeID"
                  placeholder="Soru Tipi Seçin"
                  required
                >
                  {statusUserTypes === "fulfilled" &&
                    typeUserList.data.map((_type, index) => (
                      <option value={_type.id}>{_type.name}</option>
                    ))}
                </Field>
              </FormControl>
              <FormControl mt="3">
                <FormLabel>Değer Tipi</FormLabel>
                <Field
                  as={Select}
                  name="questionTypeID"
                  placeholder="Değer Tipi Seçin"
                  required
                >
                  {statusTypes === "fulfilled" &&
                    typeList.data.map((_type, index) => (
                      <option value={_type.id}>{_type.name}</option>
                    ))}
                </Field>
              </FormControl>
              {parseInt(values.questionTypeID) > 2 &&
                parseInt(values.questionTypeID) !== 8 && (
                  <Fragment>
                    <FormControl mt="3">
                      <FormLabel>Başlangıç Değeri</FormLabel>
                      <Field
                        as={Input}
                        name="initialValue"
                        type="text"
                        required
                        minLength="1"
                      />
                    </FormControl>
                    <FormControl mt="3">
                      <FormLabel>Bitiş Değeri</FormLabel>
                      <Field as={Input} name="endValue" type="text" required />
                    </FormControl>
                  </Fragment>
                )}
            </DrawerBody>
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={props.onClose}>
                Vazgeç
              </Button>
              <Button colorScheme="blue" type="submit">
                Oluştur
              </Button>
            </DrawerFooter>
          </Form>
        )}
      </Formik>
    </DrawerContent>
  );
}

export default AddQuestion;
