export function getDayName(number: number) {
  switch (number) {
    case 0:
      return "Pazar";
    case 1:
      return "Pazartesi";
    case 2:
      return "Salı";
    case 3:
      return "Çarşamba";
    case 4:
      return "Perşembe";
    case 5:
      return "Cuma";
    case 6:
      return "Cumartesi";
  }
}

export function getDayIndex(day: string) {
  switch (day) {
    case "Pazar":
      return 0;
    case "Pazartesi":
      return 1;
    case "Salı":
      return 2;
    case "Çarşamba":
      return 3;
    case "Perşembe":
      return 4;
    case "Cuma":
      return 5;
    case "Cumartesi":
      return 6;
  }
}