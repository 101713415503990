import React, { useLayoutEffect, useRef } from "react";
import { Flex, Box, Text, Select, useToast, Skeleton } from "@chakra-ui/react";
import MainCrud from "components/templates/_Main-Crud";
import { Hooks, UserTypeListResponse } from "services";
import { useRedux } from "hooks/redux-hooks";
import AddMail from "components/organizms/AddMail";

interface MailProps {}

function Mail({}: MailProps) {
  const [{ auth }] = useRedux();
  const [update, status] = Hooks.UpdateMail();
  const [getUserTypes, statusUserTypes, dataUserTypes] = Hooks.UserTypeList();
  const toast = useToast();
  const crudRef = useRef<any>(null);

  useLayoutEffect(() => {
    getUserTypes();
  }, []);

  const typeUserList = dataUserTypes?.data as unknown as UserTypeListResponse;

  async function _update(row: any, value: any) {
    const { success } = (await update({
      ...row,
      userTypeID: parseInt(value),
    }).then((res) => res.data)) as unknown as any;
    if (success) {
      crudRef.current?.refresh();
      toast({
        title: "Başarılı!",
        description: "Soru tipi başarıyla güncellendı.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      crudRef.current?.refresh();
      toast({
        title: "İşlem Onarımda!",
        description: "Güncelleme işlemi teknik ekip tarafından onarımdadır.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <MainCrud
      newButtonText="Yeni Adres Ekle"
      title="Bildirim Adresleri"
      updateService={{ hook: Hooks.UpdateMail }}
      ref={crudRef}
      columns={[
        {
          name: "Bildirim Adresi",
          selector: "mailAddress",
          width: "250px",
          sortable: true,
        },
        {
          name: "Mail Konusu",
          selector: "subject",
          width: "250px",
          sortable: true,
        },
        {
          name: "Soru Tipi",
          selector: "userTypeName",
          width: "250px",
          sortable: true,
          cell: (row: any) => {
            return statusUserTypes === "fulfilled" && !!typeUserList.data ? (
              <Select
                minWidth="150px"
                size="xs"
                defaultValue={row.userTypeID.toString()}
                onChange={({ currentTarget: { value } }) => {
                  _update(row, value);
                }}
              >
                {typeUserList.data.map((_type, index) => (
                  <option value={_type.id} key={index}>{_type.name}</option>
                ))}
              </Select>
            ) : (
              <Box>
                <Skeleton h="20px" w="150px" />
              </Box>
            );
          },
        },
      ]}
      listService={{
        hook: Hooks.MailList,
        params: [auth.user.project.id],
      }}
      addForm={{
        Form: AddMail,
        props: [{ projectId: auth.user.project.id }],
      }}
    />
  );
}

export default Mail;
