import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Flex,
  Box,
  Text,
  Input,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Button,
  Spinner,
  Badge,
} from "@chakra-ui/react";
import SectionTitle from "components/molecules/SectionTitle";
import Main from "../templates/Main";
import Datepicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import Card from "components/atoms/Card";
import Moment from "moment";
import {
  Hooks,
  ProjectShiftListResponse,
  UserTypeListResponse,
} from "services";
import {useCurrentUser, useRedux} from "hooks/redux-hooks";
import { REQUEST_MOMENT_DATE_FORMAT } from "utils/global-constants";
import DataTable from "components/atoms/DataTable";
import { Field, Form, Formik, FormikProps } from "formik";
import { FaBan, FaCamera } from "react-icons/fa";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

type reportRequest = {
  startDate: Date;
  endDate: Date;
  questionPeriodId: number;
};
interface ReportProps {}

interface FetchFormProps {
  onSubmit: (data: reportRequest) => void;
}

function FetchForm(props: FetchFormProps) {
  const [{auth: {user: {project}}}] = useRedux()
  const [getPeriods, statusPeriod, dataPeriods] = Hooks.QuestionPeriodList();
  const [{ endDate, startDate }, setDates] = useState({
    startDate: Moment().toDate(),
    endDate: Moment().toDate(),
  });
  const [periodId, setPeriodId] = useState<number | null>(null);

  function changeDate(_type: "start" | "end", date: Date) {
    if (_type === "start") {
      if (Moment(date).diff(Moment(endDate), "days") < -31) {
        setDates((_state) => ({
          ..._state,
          endDate: Moment(date).add(1, "month").toDate(),
        }));
      }
      setDates((_state) => ({
        ..._state,
        startDate: date,
      }));
    } else if (_type === "end") {
      if (Moment(date).diff(Moment(startDate), "days") > 31) {
        setDates((_state) => ({
          ..._state,
          startDate: Moment(date).subtract(1, "month").toDate(),
        }));
      }
      setDates((_state) => ({
        ..._state,
        endDate: date,
      }));
    }
  }

  useLayoutEffect(() => {
    getPeriods();
  }, []);

  const periodList = (dataPeriods?.data as unknown) as UserTypeListResponse;
  useEffect(() => {
    if (periodList?.success && !!!periodId) {
      setPeriodId(periodList.data[0].id);
    }
  }, [periodList]);

  function handleClick() {
    props.onSubmit({ endDate, startDate, questionPeriodId: periodId! });
  }

  return (
    <Card>
      <SimpleGrid columns={2} templateColumns="3fr 2fr">
        <Flex>
          <FormControl>
            <FormLabel fontSize="xs" m="0">
              Baslangic Tarihi
            </FormLabel>
            <Input
              as={Datepicker}
              locale="tr"
              size="sm"
              minDate={Moment(project.createDate).toDate()}
              borderRadius="md"
              selected={startDate}
              dateFormat="dd.MM.yyyy"
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={(date: any) => changeDate("start", date)}
            />
          </FormControl>
          <FormControl ml="0">
            <FormLabel fontSize="xs" m="0">
              Bitis Tarihi
            </FormLabel>
            <Input
              as={Datepicker}
              locale="tr"
              size="sm"
              borderRadius="md"
              dateFormat="dd.MM.yyyy"
              selected={endDate}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              onChange={(date: any) => changeDate("end", date)}
            />
          </FormControl>
          <FormControl ml="3">
            <FormLabel fontSize="xs" m="0">
              Periyot
            </FormLabel>
            <Input
              as={Select}
              size="sm"
              borderRadius="md"
              disabled={statusPeriod !== "fulfilled"}
              value={periodId?.toString()}
              onChange={({ currentTarget: { value } }) =>
                setPeriodId(parseInt(value))
              }
            >
              {statusPeriod === "fulfilled" ? (
                periodList.data.map((period, index) => (
                  <option value={period.id} key={index}>
                    {period.name}
                  </option>
                ))
              ) : (
                <option>Yukleniyor...</option>
              )}
            </Input>
          </FormControl>
        </Flex>
        <Flex justifyContent="flex-end" alignItems="center">
          <Button
            colorScheme="green"
            disabled={!!!periodId}
            onClick={handleClick}
          >
            Getir
          </Button>
        </Flex>
      </SimpleGrid>
    </Card>
  );
}

export interface FilterForm {
  shiftID: string;
  isChecked: string;
  userTypeId: string;
  status: string;
  controlAreaName: string;
}

registerLocale("tr", tr);

export function FilterForm({
  onSubmit,
  isShowClear,
  initialValues,
  controlAreaList,
}: {
  onSubmit?: any;
  initialValues: any;
  isShowClear: boolean;
  controlAreaList: string[];
}) {
  const [getUserTypes, statusUserTypes, dataUserTypes] = Hooks.UserTypeList();
  // const [getShifts, statShifts, dataShifts] = Hooks.ProjectShiftList();
  const formRef = useRef<FormikProps<FilterForm>>(null);
  const user = useCurrentUser();

  function _handleSubmit(values: FilterForm) {
    let filterForm: any = {};
    Object.entries(values).forEach(([key, value]) => {
      if (value != -1) {
        if (key === "isChecked") {
          return (filterForm[key] = value == 1 ? true : false);
        } else if (key === "status") {
          return (filterForm[key] = value == 1 ? true : false);
        }
        filterForm[key] = value;
      }
    });
    onSubmit(filterForm);
  }

  function _handleClickReset() {
    formRef.current?.setValues({
      isChecked: "-1",
      shiftID: "-1",
      status: "-1",
      userTypeId: "-1",
      controlAreaName: "-1",
    });
    onSubmit({});
  }

  useLayoutEffect(() => {
    getUserTypes();
    // getShifts(user.project.id);
  }, []);

  const typeUserList = (dataUserTypes?.data as unknown) as UserTypeListResponse;
  // const shiftList = (dataShifts?.data as unknown) as ProjectShiftListResponse;

  return (
    <Card mt="3">
      <Formik
        initialValues={{
          isChecked: "-1",
          shiftID: "-1",
          status: "-1",
          userTypeId: "-1",
          ...initialValues,
        }}
        onSubmit={_handleSubmit}
        innerRef={formRef}
      >
        <Form>
          <SimpleGrid columns={6} spacing="2">
            <FormControl>
              <FormLabel fontSize="xs" m="0">
                Kontrol Bolgesi
              </FormLabel>
              <Field
                as={Select}
                size="sm"
                borderRadius="md"
                name="controlAreaName"
              >
                  <Fragment>
                    <option value="-1">Tümü</option>
                    {controlAreaList.map((controlArea, index) => (
                      <option value={controlArea} key={index}>
                        {controlArea}
                      </option>
                    ))}
                  </Fragment>
              </Field>
            </FormControl>
            {/* <FormControl>
              <FormLabel fontSize="xs" m="0">
                Kontrol Saati
              </FormLabel>
              <Field as={Select} size="sm" borderRadius="md" name="shiftID">
                {statShifts !== "fulfilled" ? (
                  <option>Yukleniyor...</option>
                ) : (
                  <Fragment>
                    <option value="-1">Tümü</option>
                    {shiftList.data.filter(item => item.isActive).map((shift, index) => (
                      <option value={shift.id} key={index}>
                        {shift.startHour} - {shift.endHour}
                      </option>
                    ))}
                  </Fragment>
                )}
              </Field>
            </FormControl> */}
            <FormControl>
              <FormLabel fontSize="xs" m="0">
                Yanit Durumu
              </FormLabel>
              <Field as={Select} size="sm" borderRadius="md" name="isChecked">
                <option value="-1">Tümü</option>
                <option value="1">Yanıtlanan</option>
                <option value="0">Yanıtlanmayan</option>
              </Field>
            </FormControl>
            <FormControl>
              <FormLabel fontSize="xs" m="0">
                Yanitlar<small>(Olumlu/Olumsuz)</small>
              </FormLabel>
              <Field as={Select} size="sm" borderRadius="md" name="status">
                <option value="-1">Tümü</option>
                <option value="1">Olumlu</option>
                <option value="2">Olumsuz</option>
              </Field>
            </FormControl>
            <FormControl>
              <FormLabel fontSize="xs" m="0">
                Soru Tipi
              </FormLabel>
              <Field
                as={Select}
                size="sm"
                borderRadius="md"
                disabled={statusUserTypes !== "fulfilled"}
                name="userTypeId"
              >
                {statusUserTypes === "fulfilled" ? (
                  <Fragment>
                    <option value="-1">Tümü</option>
                    {typeUserList.data.map((userType, index) => (
                      <option value={userType.id} key={index}>
                        {userType.name}
                      </option>
                    ))}
                  </Fragment>
                ) : (
                  <option>Yukleniyor</option>
                )}
              </Field>
            </FormControl>
            <Flex justifyContent="flex-end" alignItems="center">
              {isShowClear && (
                <Button
                  colorScheme="orange"
                  variant="outline"
                  mr="1"
                  size="sm"
                  onClick={_handleClickReset}
                >
                  Temizle
                </Button>
              )}
              <Button colorScheme="purple" size="sm" type="submit">
                Filtrele
              </Button>
            </Flex>
          </SimpleGrid>
        </Form>
      </Formik>
    </Card>
  );
}

function Report({}: ReportProps) {
  const [getReport, statusReport, dateReport] = Hooks.Report();
  const [filterData, setFilterData] = useState<Partial<FilterForm>>({});
  const user = useCurrentUser();

  function _getReport(data: reportRequest) {
    getReport(
      user?.project.id,
      data.questionPeriodId,
      Moment(data.startDate).format(REQUEST_MOMENT_DATE_FORMAT),
      Moment(data.endDate).format(REQUEST_MOMENT_DATE_FORMAT)
    );
  }

  function _filter(data: any) {
    let _filters: boolean[] = [];
    Object.entries(filterData).forEach(([key, value]) => {
      console.log({ data: data[key], value, key, cond: data[key] != value });
      if (data[key] != value) _filters.push(false);
    });
    return !_filters.includes(false);
  }

  function createInitialValues(_filterData: any) {
    let initialValues: any = {};
    Object.entries(_filterData).forEach(([key, value]) => {
      if (key === "isChecked") {
        initialValues[key] = value ? "1" : "0";
      } else if (key === "status") {
        initialValues[key] = value === null ? "-1" : !!value ? "1" : "2";
      } else {
        initialValues[key] = value;
      }
    });
    return initialValues;
  }

  let controlAreaList: string[] = [];
  if (dateReport?.data.data) {
    dateReport.data.data.forEach((item) => {
      controlAreaList.push(item.controlAreaName);
    });
    controlAreaList = [...(new Set(controlAreaList) as any)] as string[];
  }

  return (
    <Main>
      <SectionTitle title="Kontrol Sonuçları" />
      <FetchForm onSubmit={_getReport} />
      {statusReport === "fulfilled" && (
        <FilterForm
          isShowClear={Object.values(filterData).length > 0}
          initialValues={createInitialValues(filterData)}
          onSubmit={(data: Partial<FilterForm>) => setFilterData(data)}
          controlAreaList={controlAreaList}
        />
      )}
      {statusReport !== null && (
        <ReportTable
          loading={statusReport !== "fulfilled"}
          data={dateReport?.data.data.filter(_filter)}
        />
      )}
    </Main>
  );
}

export function ReportTable({ loading, data }: any) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const columns = [
    {
      name: "Kod",
      selector: "barcode",
      width: "120px",
      sortable: true,
    },
    {
      name: "Kontrol Tarihi",
      selector: "controlDate",
      width: "200px",
      sortable: true,
    },
    {
      name: "Vardiya",
      selector: "shiftName",
      width: "200px",
      sortable: true,
    },
    {
      name: "Kontrol Bölgesi",
      selector: "controlAreaName",
      width: "300px",
      sortable: true,
    },
    {
      name: "Kontrol Noktası",
      selector: "controlPointName",
      width: "200px",
      sortable: true,
    },
    {
      name: "Kontrol Sorusu",
      selector: "questionText",
      width: "400px",
      sortable: true,
    },
    {
      name: "Soru Tipi",
      selector: "userTypeName",
      width: "200px",
      sortable: true,
    },
    {
      name: "Değer Tipi",
      selector: "questionTypeName",
      width: "200px",
      sortable: true,
    },
    {
      name: "Başlangıç Değeri",
      selector: "initialValue",
      width: "140px",
      sortable: true,
    },
    {
      name: "Bitis Degeri",
      selector: "endValue",
      sortable: true,
    },
    {
      name: "Yanıt",
      selector: "userAnswer",
      sortable: true,
    },
    {
      name: "Not",
      selector: "description",
      width: "200px",
      sortable: true,
    },
    {
      name: "Yanıt Durumu",
      width: "140px",
      sortable: true,
      selector: "status",
      cell: (row: any) =>
        row.status === null || row.status === undefined ? (
          <Badge colorScheme="orange">Yanitlanmadi</Badge>
        ) : !!row.status ? (
          <Badge colorScheme="green">Olumlu</Badge>
        ) : (
          <Badge colorScheme="red">Olumsuz</Badge>
        ),
    },
    {
      name: "Kontrolü Gerçekleştiren",
      selector: "checkedUserName",
      width: "200px",
      sortable: true,
    },
    {
      name: "Gerçekleşen Tarih",
      sortable: true,
      selector: "createDate",
      cell: (row: any) => {
        if(!row.createDate) return ""

        return `${row.createDate}`
      },
      width: "160px",
    },
    {
      name: "Periyod",
      sortable: true,
      selector: "questionPeriodName",
    },
    {
      name: "Fotoğraf",
      cell: (row: any) => {
        if (!!!row.photoPath)
          return (
            <Flex justifyContent="center">
              <FaBan />
            </Flex>
          );

        return (
          <Flex justifyContent="center">
            <Button
              size="xs"
              colorScheme="blue"
              onClick={() => setImageSrc(row.photoPath)}
            >
              <FaCamera />
            </Button>
          </Flex>
        );
      },
    },
  ];
  return (
    <Fragment>
      <Card mt="1">
        {loading ? (
          <Flex justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          !!data && (
            <DataTable
              columns={columns}
              data={data}
              excel={columns
                .filter((item) => !!item.selector)
                .map((item) => ({ label: item.name, value: item.selector }))}
            />
          )
        )}
      </Card>
      {!!imageSrc && (
        <Lightbox mainSrc={imageSrc} onCloseRequest={() => setImageSrc(null)} />
      )}
    </Fragment>
  );
}

export default Report;
