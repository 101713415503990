import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  FormErrorMessage,
  styled,
  Text,
  Flex,
  Progress,
  SimpleGrid,
  Icon,
  Skeleton,
  useMediaQuery,
} from "@chakra-ui/react";
import DatePicker, { registerLocale } from "react-datepicker";
import MainTemplate from "components/templates/Main";
import { Formik, FormikProps, Form, Field } from "formik";
import * as Yup from "yup";
import { useCurrentUser, useRedux } from "hooks/redux-hooks";
import { setAppName } from "store/app/actions";
import SectionTitle from "components/molecules/SectionTitle";
import "react-datepicker/dist/react-datepicker.css";
import Card from "components/atoms/Card";
import PieCard from "components/molecules/PieCard";
import InfoCard from "components/molecules/InfoCard";
import {
  FaCalculator,
  FaMinus,
  FaCalendar,
  FaBan,
  FaCircle,
  FaCalendarDay,
} from "react-icons/fa";
import Moment from "moment";
import { extendMoment } from "moment-range";
import tr from "date-fns/locale/tr";
import { Hooks } from "services";
import { REQUEST_MOMENT_DATE_FORMAT } from "utils/global-constants";
import { ReportTable } from "./Report";

interface IForm {
  fullname: string;
  email: string;
  username: string;
}

registerLocale("tr", tr);

function FetchForm({ onSubmit, onLive }: any) {
  const [
    {
      auth: {
        user: { project },
      },
    },
  ] = useRedux();
  const [{ startDate, endDate }, setDates] = useState({
    startDate: Moment().toDate(),
    endDate: Moment().toDate(),
  });
  const [isLive, setLive] = useState(false);

  function changeDate(_type: "start" | "end", date: Date) {
    if (_type === "start") {
      if (Moment(date).diff(Moment(endDate), "days") < -31) {
        setDates((_state) => ({
          ..._state,
          endDate: Moment(date).add(1, "month").toDate(),
        }));
      }
      setDates((_state) => ({
        ..._state,
        startDate: date,
      }));
    } else if (_type === "end") {
      if (Moment(date).diff(Moment(startDate), "days") > 31) {
        setDates((_state) => ({
          ..._state,
          startDate: Moment(date).subtract(1, "month").toDate(),
        }));
      }
      setDates((_state) => ({
        ..._state,
        endDate: date,
      }));
    }
  }

  return (
    <Flex alignItems="center">
      <Flex>
        <Input
          as={DatePicker}
          locale="tr"
          selected={isLive ? new Date() : startDate}
          onChange={(date: any) => changeDate("start", date)}
          dateFormat="dd.MM.yyyy"
          selectsStart
          minDate={Moment(project.createDate).toDate()}
          startDate={startDate}
          endDate={endDate}
          size="sm"
          rounded="md"
          disabled={isLive}
          borderColor="gray.400"
        />
        <Input
          as={DatePicker}
          selected={isLive ? new Date() : endDate}
          onChange={(date: any) => changeDate("end", date)}
          dateFormat="dd.MM.yyyy"
          locale="tr"
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          size="sm"
          rounded="md"
          ml="1"
          disabled={isLive}
          borderColor="gray.400"

        />
      </Flex>
      <Button
        colorScheme="blue"
        size="sm"
        marginX="3"
        disabled={isLive}
        onClick={() => onSubmit(startDate, endDate)}
      >
        Getir
      </Button>
      <Icon as={FaMinus} />
      <Button
        colorScheme="green"
        size="sm"
        variant={isLive ? undefined : "outline"}
        borderWidth="1px"
        boxShadow="none !important"
        onClick={(e) => {
          setLive(!isLive);
          onLive();
        }}
        marginLeft="3"
      >
        Canlı
      </Button>
    </Flex>
  );
}

function Dashboard() {
  const [getDashs, statDashs, dataDashs] = Hooks.Dashboard();
  const [getReportM, statReportM, dataReportsM] = Hooks.Report();
  const [getReportW, statReportW, dataReportsW] = Hooks.Report();
  const [getReportD, statReportD, dataReportsD] = Hooks.Report();
  const [isLive, setLive] = useState(false);
  const [counter, setCounter] = useState<NodeJS.Timeout>();
  const [lastRequestDate, setLastRequestDate] = useState<Date>();
  const [progressValue, setProgressValue] = useState<number>(100);
  const [progressCounter, setProgressCounter] = useState<NodeJS.Timeout>();
  const [{ app }, dispatch] = useRedux();
  const user = useCurrentUser();
  const [isMobile] = useMediaQuery("(max-width: 650px)");
  const [activeShift, setActiveShift] = useState(0);

  function _handleSubmit(startDate: Date, endDate: Date) {
    getDashs(
      user.project.id,
      Moment(startDate).format(REQUEST_MOMENT_DATE_FORMAT),
      Moment(endDate).format(REQUEST_MOMENT_DATE_FORMAT)
    );
    getReportD(
      user.project.id,
      1,
      Moment(startDate).format(REQUEST_MOMENT_DATE_FORMAT),
      Moment(endDate).format(REQUEST_MOMENT_DATE_FORMAT)
    );
    getReportW(
      user.project.id,
      2,
      Moment(startDate).format(REQUEST_MOMENT_DATE_FORMAT),
      Moment(endDate).format(REQUEST_MOMENT_DATE_FORMAT)
    );
    getReportM(
      user.project.id,
      3,
      Moment(startDate).format(REQUEST_MOMENT_DATE_FORMAT),
      Moment(endDate).format(REQUEST_MOMENT_DATE_FORMAT)
    );
    setLastRequestDate(new Date());
  }

  function _handleLive() {
    if (!isLive) {
      const _progressCounter = setInterval(async () => {
        setProgressValue((_state) => (_state > -1 ? _state - 1 : 0));
      }, 50);
      setProgressCounter(_progressCounter);
    } else {
      if (progressCounter) {
        clearInterval(progressCounter);
        setProgressCounter(undefined);
        setProgressValue(100);
      }
    }
    setLive(!isLive);
  }

  useLayoutEffect(() => {
    const params = [
      user.project.id,
      Moment().format(REQUEST_MOMENT_DATE_FORMAT),
      Moment().format(REQUEST_MOMENT_DATE_FORMAT),
    ];
    getDashs(...params);
    getReportD(
      user.project.id,
      1,
      Moment().format(REQUEST_MOMENT_DATE_FORMAT),
      Moment().format(REQUEST_MOMENT_DATE_FORMAT)
    );
    getReportW(
      user.project.id,
      2,
      Moment().format(REQUEST_MOMENT_DATE_FORMAT),
      Moment().format(REQUEST_MOMENT_DATE_FORMAT)
    );
    getReportM(
      user.project.id,
      3,
      Moment().format(REQUEST_MOMENT_DATE_FORMAT),
      Moment().format(REQUEST_MOMENT_DATE_FORMAT)
    );
    setLastRequestDate(new Date());
  }, []);

  async function watchProgress() {
    if (
      progressValue === 0 &&
      statDashs !== "pending" &&
      statReportD !== "pending" &&
      statReportM !== "pending" &&
      statReportW !== "pending"
    ) {
      await getDashs(
        user.project.id,
        Moment().format(REQUEST_MOMENT_DATE_FORMAT),
        Moment().format(REQUEST_MOMENT_DATE_FORMAT)
      );
      await getReportD(
        user.project.id,
        1,
        Moment().format(REQUEST_MOMENT_DATE_FORMAT),
        Moment().format(REQUEST_MOMENT_DATE_FORMAT)
      );
      await getReportW(
        user.project.id,
        2,
        Moment().format(REQUEST_MOMENT_DATE_FORMAT),
        Moment().format(REQUEST_MOMENT_DATE_FORMAT)
      );
      await getReportM(
        user.project.id,
        3,
        Moment().format(REQUEST_MOMENT_DATE_FORMAT),
        Moment().format(REQUEST_MOMENT_DATE_FORMAT)
      );
      setProgressValue(100);
      setLastRequestDate(new Date());
    }
  }

  useEffect(() => {
    watchProgress();
  }, [progressValue]);

  useEffect(() => {
    if (
      statDashs === "fulfilled" &&
      dataDashs?.data.data.daily &&
      dataDashs?.data.data.daily.length > 0
    ) {
      const { daily } = dataDashs.data.data;
      daily.forEach((d, index) => {
        const [startStr, endStr] = d.shift.split(" - ");
        const startDate = Moment(startStr, "HH:mm");
        const endDate = Moment(endStr, "HH:mm");
        const date = Moment();

        const isRange = date.isBetween(startDate, endDate);

        if (isRange) {
          setActiveShift(index);
        }
      });
    }
  }, [statDashs]);

  return (
    <MainTemplate>
      <Flex
        justifyContent="space-between"
        alignItems={isMobile ? "flex-start" : "center"}
        flexDirection={isMobile ? "column" : "row"}
      >
        <SectionTitle title="Kontrol Merkezi" />
        <FetchForm onSubmit={_handleSubmit} onLive={_handleLive} />
      </Flex>
      <Flex mt="3" justifyContent="space-between" alignItems="center">
        <Text fontSize="xs">
          Son Güncelleme Tarihi:{" "}
          {Moment(lastRequestDate).format("DD.MM.YYYY HH:mm:ss")}
        </Text>
        <Box w="200px">
          {progressCounter && (
            <Progress
              transition="none"
              max={90}
              value={progressValue}
              rounded="sm"
            />
          )}
        </Box>
      </Flex>
      <SimpleGrid columns={isMobile ? 1 : 4} spacing="3" mt="2">
        <Skeleton
          minH="150px"
          isLoaded={statDashs === "fulfilled"}
          borderRadius="md"
        >
          <InfoCard
            minH="200px"
            title="Günlük"
            icon={FaCalendarDay}
            value={
              statDashs === "fulfilled"
                ? (dataDashs!.data.data as any).dailyPositive!
                : 0
            }
            total={
              statDashs === "fulfilled"
                ? (dataDashs!.data.data as any).dailyPositive! +
                  (dataDashs!.data.data as any).dailyNegative!
                : 0
            }
            cardColor="green"
            isLoading={statDashs === "pending"}
          />
        </Skeleton>
        <Skeleton
          minH="150px"
          isLoaded={statDashs === "fulfilled"}
          borderRadius="md"
        >
          <InfoCard
            minH="200px"
            title="Haftalik"
            icon={FaCalendarDay}
            value={
              statDashs === "fulfilled"
                ? dataDashs!.data.data.weeklyPositive!
                : 0
            }
            total={
              statDashs === "fulfilled"
                ? dataDashs!.data.data.weeklyPositive! +
                  dataDashs!.data.data.weeklyNegative!
                : 0
            }
            cardColor="green"
            isLoading={statDashs === "pending"}
          />
        </Skeleton>
        <Skeleton
          minH="150px"
          isLoaded={statDashs === "fulfilled"}
          borderRadius="md"
        >
          <InfoCard
            minH="150px"
            title="Aylik"
            icon={FaCalendar}
            value={
              statDashs === "fulfilled"
                ? dataDashs!.data.data.monthlyPositive!
                : 0
            }
            total={
              statDashs === "fulfilled"
                ? dataDashs!.data.data.monthlyPositive! +
                  dataDashs!.data.data.monthlyNegative!
                : 0
            }
            cardColor="green"
            isLoading={statDashs === "pending"}
          />
        </Skeleton>
        <Skeleton
          minH="150px"
          isLoaded={
            statReportW === "fulfilled" &&
            statReportD === "fulfilled" &&
            statReportM === "fulfilled"
          }
          borderRadius="md"
        >
          {statReportW === "fulfilled" &&
            statReportD === "fulfilled" &&
            statReportM === "fulfilled" && (
              <InfoCard
                minH="150px"
                title="Olumsuz Yanitlar"
                icon={FaBan}
                value={
                  dataReportsM!.data.data.filter(
                    (item) => item.status === false
                  ).length +
                  dataReportsD!.data.data.filter(
                    (item) => item.status === false
                  ).length +
                  dataReportsW!.data.data.filter(
                    (item) => item.status === false
                  ).length
                }
                cardColor="red"
                isLoading={statDashs === "pending"}
              />
            )}
        </Skeleton>
      </SimpleGrid>
      <Box mt="10">
        <SectionTitle color="red" title="Olumsuz Yanitlar" />
      </Box>
      <Skeleton
        minH="300px"
        borderRadius="md"
        isLoaded={
          statReportW === "fulfilled" &&
          statReportD === "fulfilled" &&
          statReportM === "fulfilled"
        }
      >
        <ReportTable
          data={
            statReportW === "fulfilled" &&
            statReportD === "fulfilled" &&
            statReportM === "fulfilled"
              ? [
                  ...dataReportsM!.data.data.filter(
                    (item) => item.status === false
                  ),
                  ...dataReportsD!.data.data.filter(
                    (item) => item.status === false
                  ),
                  ...dataReportsW!.data.data.filter(
                    (item) => item.status === false
                  ),
                ]
              : []
          }
          loading={
            !(
              statReportW === "fulfilled" &&
              statReportD === "fulfilled" &&
              statReportM === "fulfilled"
            )
          }
        />
      </Skeleton>
    </MainTemplate>
  );
}

export default Dashboard;
