import { Box, Divider, Flex, Text, useColorMode } from "@chakra-ui/react";
import { ChatIcon } from "@chakra-ui/icons";
import React, { Fragment, ReactElement } from "react";
import { useHistory } from "react-router-dom";

export interface BasicTileProps {
  title: string;
  icon: ReactElement;
  path: string;
}

function BasicTile({ icon, title, path }: BasicTileProps) {
  const { colorMode } = useColorMode();
  const history = useHistory();
  return (
    <Flex
      p="3"
      px="5"
      cursor="pointer"
      alignItems="center"
      _hover={{ bgColor: "gray.700" }}
      _active={{ bgColor: "gray.900" }}
      onClick={() => {
        history.push(path);
      }}
      bgColor={history.location.pathname === path ? "gray.700" : "inherit"}
      color="white"
      fontWeight="semibold"
      marginBottom="2"
      borderRadius="md"
    >
      <Box w="30px">{icon}</Box>
      <Text fontSize="sm">{title}</Text>
    </Flex>
  );
}

export default BasicTile;
