import React, { useEffect, useState } from "react";
import {
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  Button,
  FormControl,
  FormLabel,
  useToast,
  Select,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { Hooks } from "services";
import { useRedux } from "hooks/redux-hooks";
import DaySelect from "../molecules/DaySelect";

interface AddControlAreaProps {
  onClose: () => void;
  projectId: number;
  onSuccess?: () => void;
}

interface Form {
  barcode: string;
  name: string;
  dayOfMonth: string;
}

function AddControlArea(props: AddControlAreaProps) {
  const [getAddCA, statusCA, dataCA] = Hooks.AddControlAreaWith();
  const [period, setPeriod] = useState<number>(1);
  const [days, setDays] = useState<number[]>([]);
  const [{ auth }] = useRedux();
  const toast = useToast();

  async function onValid(values: Form) {

    //ControlArea
    //ControlAreaID => Period
    //PeriodID => Shift

    getAddCA({
      projectID: auth.user.project.id,
      addUserID: auth.user.id,
      barcode: values.barcode,
      name: values.name,
      periodType: period,
      dayOfMonth: period === 3 ? Number(values.dayOfMonth) : 0,
      daysOfWeek: period !== 3 ? days : [],
    });
  }

  useEffect(() => {
    if (period === 2 && days.length > 0) {
      setDays([days[0]]);
    }
  }, [period]);

  useEffect(() => {
    const _data = dataCA?.data as unknown as any;
    if (statusCA === "fulfilled") {
      toast({
        title: "Başarılı!",
        description: "Kontrol bölgesi başarıyla oluşturuldu.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      props.onSuccess && props.onSuccess();
      props.onClose();
    } else if (statusCA === "rejected") {
      toast({
        title: "Hata Oluştu!",
        description: _data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [statusCA]);

  return (
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Kontrol Bölgesi Oluştur</DrawerHeader>

      <Formik<Form>
        initialValues={{ barcode: "", name: "", dayOfMonth: "1" }}
        onSubmit={onValid}
      >
        <Form>
          <DrawerBody>
            <FormControl mt="3">
              <FormLabel>Kontrol Bölgesi Adı</FormLabel>
              <Field as={Input} name="name" />
            </FormControl>
            <FormControl mt="3">
              <FormLabel>Kod</FormLabel>
              <Field as={Input} name="barcode" />
            </FormControl>
            <FormControl mt="3" mb="5">
              <FormLabel>Periyot</FormLabel>
              <Select
                onChange={(e) => setPeriod(Number(e.target.value))}
                value={period.toString()}
              >
                <option value="1">Günlük</option>
                <option value="2">Haftalık</option>
                <option value="3">Aylık</option>
              </Select>
            </FormControl>
            {period !== 3 && (
              <DaySelect
                isMultiple={period === 1}
                onChange={(values) => setDays(values)}
              />
            )}
            {period === 3 && (
              <FormControl mt="3">
                <FormLabel>Ayın Günü</FormLabel>
                <Field
                  as={Input}
                  type="number"
                  max="28"
                  min="1"
                  defaultValue="1"
                  name="dayOfMonth"
                />
              </FormControl>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              Vazgeç
            </Button>
            <Button colorScheme="blue" type="submit">
              Oluştur
            </Button>
          </DrawerFooter>
        </Form>
      </Formik>
    </DrawerContent>
  );
}

export default AddControlArea;
